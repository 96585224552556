import { Component, Vue, Prop } from "vue-property-decorator";
import { TeamMember } from "@/types/team";
import { HtmlElementClassName } from "@/types/element";

@Component
export default class TeamMemberActions extends Vue {
  @Prop({ type: Object, required: true })
  private readonly teamMember!: TeamMember;

  private get teamMemberActions() {
    return [
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.delete"),
        icon: "$trash",
        iconColor: "red",
        className: HtmlElementClassName.teamMemberDeleteAction,
        enabled: this.teamMember.enabled,
        onClick: () => {
          this.$emit("delete:team-member", this.teamMember);
        },
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.activate"),
        icon: "$refresh",
        iconColor: "primary",
        className: HtmlElementClassName.teamMemberDeleteAction,
        enabled: !this.teamMember.enabled,
        onClick: () => {
          this.$emit("activate:team-member", this.teamMember);
        },
      },
    ].filter(({ enabled = true }) => enabled);
  }
}
